import { createSlice } from "@reduxjs/toolkit";
import Cookies from "../../../services/cookies";
import {
  ForgetPasswordThunk,
  LoginThunk,
  LogoutThunk,
  ResetPasswordThunk,
  SignUpThunk,
} from "./Thunk";

const token = Cookies.get("token");

const user = Cookies.get("user");

const initialState = {
  isAuth: !!token,
  user: !!user ? user : null,
  loading: false,
  token: !!token ? token : "",
  error: null,
  forget_redirection_link: "",
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    logout: (state) => {
      state.isAuth = false;
      state.user = null;
      state.token = "";
    },
    setLoginError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Login
    builder.addCase(LoginThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(LoginThunk.fulfilled, (state, action) => {
      Cookies?.set("user", action.payload?.data?.user);
      state.user = action.payload?.data?.user;
      state.loading = false;
      state.isAuth = true;
    });
    builder.addCase(LoginThunk.rejected, (state, { payload }) => {
      state.error = payload?.message;
      state.loading = false;
    });
    //Logout
    builder.addCase(LogoutThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(LogoutThunk.fulfilled, (state, action) => {
      state.isAuth = false;
      state.user = null;
      state.token = "";
    });
    builder.addCase(LogoutThunk.rejected, (state, { payload }) => {
      state.error = payload?.message;
      state.loading = false;
    });
    //Sign Up
    builder.addCase(SignUpThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(SignUpThunk.fulfilled, (state, action) => {
      state.user = action.payload?.data?.user;
      state.loading = false;
      // state.isAuth = true;
    });
    builder.addCase(SignUpThunk.rejected, (state, { payload }) => {
      state.error = payload?.message;
      state.loading = false;
    });
    //Forget Password
    builder.addCase(ForgetPasswordThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(ForgetPasswordThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.forget_redirection_link =
        action?.payload?.data?.reset_password_link;
    });
    builder.addCase(ForgetPasswordThunk.rejected, (state, { payload }) => {
      state.error = payload?.message;
      state.loading = false;
    });
    //Reset Password
    builder.addCase(ResetPasswordThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(ResetPasswordThunk.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(ResetPasswordThunk.rejected, (state, { payload }) => {
      state.error = payload?.message;
      state.loading = false;
    });
  },
});

export const { logout, setLoginError, setAuth } = authSlice.actions;

export default authSlice.reducer;
