import { configureStore } from "@reduxjs/toolkit";
import Layout from "../layout/slice/layoutSlice";
import Auth from "../modules/Auth/slice/AuthSlice";
import Profile from "../modules/Profile/slice/ProfileSlice";
import QC from "../modules/QCManagement/slice/QCSlices";
import Resource from "../modules/Resources/slice/ResourceSlice";
import Workspace from "../modules/Workspace/slice/workspaceSlice";
import common from "./common/commonSlice";
import wallet from "../modules/Wallet/slice/WalletSlice";
import dashboard from "../modules/Dashboard/slices/DashboardSlice";
const store = configureStore({
  reducer: {
    common,
    Auth,
    Layout,
    Resource,
    Workspace,
    Profile,
    QC,
    wallet,
    dashboard,
  },
  devTools: true,
});

export default store;
