import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import {
  FaAngleDown,
  FaLaptopCode,
  FaQrcode,
  FaSquarePollVertical,
  FaUserGroup,
  FaWallet,
} from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import routesConstants from "../routes/routesConstants";
import { useSelector } from "react-redux";

const parentMenu = {
  "QC-Management": "QCMenuOpen",
  "work-space": "WorkspaceMenuOpen",
  wallet: "WalletMenuOpen",
  resources: "resourcesMenuOpen",
};

const Sidebar = () => {
  const [toggle, setToggle] = useState({});
  const location = useLocation();
  const { user } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (
      !!!location?.pathname.split("/")?.at(1) &&
      !!!parentMenu[location?.pathname.split("/")?.at(1)]
    )
      return;
    setToggle(
      { [parentMenu[location?.pathname.split("/")?.at(1)]]: true } || {}
    );
  }, [location]);

  function toggleMenuState(menuState) {
    if (!!toggle && toggle[menuState]) {
      setToggle({ [menuState]: false });
    } else if (!!toggle && Object.keys(toggle).length === 0) {
      setToggle({ [menuState]: true });
    } else {
      Object.keys(toggle).forEach((i) => {
        setToggle({ [i]: false });
      });
      setToggle({ [menuState]: true });
    }
  }

  const isPathActive = (path, isParent = 0) => {
    if (isParent === 1) return location.pathname.includes(path);
    return location.pathname === path;
  };

  useEffect(() => {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, []);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li
          className={
            isPathActive("/" + routesConstants.HOME_PAGE, 1)
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link className="nav-link" to={routesConstants.HOME_PAGE}>
            <FaQrcode className="menu-icon me-4 default-font-size" />
            <span className="menu-title">Dashboard</span>
          </Link>
        </li>
        {!!user?.permission?.is_qc ? (
          <li
            className={
              isPathActive("/" + routesConstants.QC_MANAGEMENT, 1)
                ? "nav-item active"
                : "nav-item"
            }
          >
            <div
              className={
                toggle?.QCMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState("QCMenuOpen")}
            >
              <>
                <FaLaptopCode className="menu-icon me-4 default-font-size" />
                <span className="menu-title">QC Management</span>
              </>
              <i className="menu-arrow"></i>
              <FaAngleDown className={`collapse-arrow`} />
            </div>

            <Collapse in={toggle?.QCMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive(
                        "/" +
                          routesConstants.QC_MANAGEMENT +
                          "/" +
                          routesConstants.QC_NEW_DATA
                      )
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={
                      "/" +
                      routesConstants.QC_MANAGEMENT +
                      "/" +
                      routesConstants.QC_NEW_DATA
                    }
                  >
                    New Data
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive(
                        "/" +
                          routesConstants.QC_MANAGEMENT +
                          "/" +
                          routesConstants.QC_SAVE_DATA
                      )
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={
                      "/" +
                      routesConstants.QC_MANAGEMENT +
                      "/" +
                      routesConstants.QC_SAVE_DATA
                    }
                  >
                    Saved Data
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive(
                        "/" +
                          routesConstants.QC_MANAGEMENT +
                          "/" +
                          routesConstants.QC_SUBMIT_DATA
                      )
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={
                      "/" +
                      routesConstants.QC_MANAGEMENT +
                      "/" +
                      routesConstants.QC_SUBMIT_DATA
                    }
                  >
                    Submitted Data
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        ) : (
          <></>
        )}
        {!!user?.permission?.is_freelancer ? (
          <li
            className={
              isPathActive("/" + routesConstants.WORK_SPACE, 1)
                ? "nav-item active"
                : "nav-item"
            }
          >
            <div
              className={
                toggle?.WorkspaceMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("WorkspaceMenuOpen")}
            >
              <>
                <FaLaptopCode className="menu-icon me-4 default-font-size" />
                <span className="menu-title">Workspace</span>
              </>
              <i className="menu-arrow"></i>
              <FaAngleDown className={`collapse-arrow`} />
            </div>

            <Collapse in={toggle?.WorkspaceMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive(
                        "/" +
                          routesConstants.WORK_SPACE +
                          "/" +
                          routesConstants.NEW_DATA
                      )
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={
                      "/" +
                      routesConstants.WORK_SPACE +
                      "/" +
                      routesConstants.NEW_DATA
                    }
                  >
                    New Data
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive(
                        "/" +
                          routesConstants.WORK_SPACE +
                          "/" +
                          routesConstants.SAVE_DATA
                      )
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={
                      "/" +
                      routesConstants.WORK_SPACE +
                      "/" +
                      routesConstants.SAVE_DATA
                    }
                  >
                    Saved Data
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive(
                        "/" +
                          routesConstants.WORK_SPACE +
                          "/" +
                          routesConstants.SUBMIT_DATA
                      )
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={
                      "/" +
                      routesConstants.WORK_SPACE +
                      "/" +
                      routesConstants.SUBMIT_DATA
                    }
                  >
                    Submitted Data
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive(
                        "/" +
                          routesConstants.WORK_SPACE +
                          "/" +
                          routesConstants.MY_QUERY
                      )
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={
                      "/" +
                      routesConstants.WORK_SPACE +
                      "/" +
                      routesConstants.MY_QUERY
                    }
                  >
                    My Queries
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        ) : (
          <></>
        )}

        <li
          className={
            isPathActive("/" + routesConstants.WALLET, 1)
              ? "nav-item active"
              : "nav-item"
          }
        >
          <div
            className={
              toggle?.WalletMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("WalletMenuOpen")}
          >
            <>
              <FaWallet className="menu-icon me-4 default-font-size" />
              <span className="menu-title">Wallet</span>
            </>
            <i className="menu-arrow"></i>
            <FaAngleDown className={`collapse-arrow`} />
          </div>

          <Collapse in={toggle?.WalletMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link
                  className={
                    isPathActive(
                      "/" +
                        routesConstants.WALLET +
                        "/" +
                        routesConstants.EARNING_HISTORY
                    )
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to={
                    "/" +
                    routesConstants.WALLET +
                    "/" +
                    routesConstants.EARNING_HISTORY
                  }
                >
                  Earning History
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    isPathActive(
                      "/" +
                        routesConstants.WALLET +
                        "/" +
                        routesConstants.PENDING_WALLET
                    )
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to={
                    "/" +
                    routesConstants.WALLET +
                    "/" +
                    routesConstants.PENDING_WALLET
                  }
                >
                  Pending Wallet Requests
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>

        <li
          className={
            isPathActive("/" + routesConstants.RESOURCES, 1)
              ? "nav-item active"
              : "nav-item"
          }
        >
          <div
            className={
              toggle?.resourcesMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("resourcesMenuOpen")}
          >
            <>
              <FaUserGroup className="menu-icon me-4 default-font-size" />
              <span className="menu-title">Resources</span>
            </>
            <i className="menu-arrow"></i>
            <FaAngleDown className={`collapse-arrow`} />
          </div>

          <Collapse in={toggle?.resourcesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link
                  className={
                    isPathActive(
                      "/" +
                        routesConstants.RESOURCES +
                        "/" +
                        routesConstants.GUIDE_LINE
                    )
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to={
                    "/" +
                    routesConstants.RESOURCES +
                    "/" +
                    routesConstants.GUIDE_LINE
                  }
                >
                  Guide Line
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    isPathActive(
                      "/" +
                        routesConstants.RESOURCES +
                        "/" +
                        routesConstants.TERMS_CONDITION
                    )
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to={
                    "/" +
                    routesConstants.RESOURCES +
                    "/" +
                    routesConstants.TERMS_CONDITION
                  }
                >
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>

        <li
          className={
            isPathActive("/" + routesConstants.REPORT, 1)
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link className="nav-link" to={routesConstants.REPORT}>
            <FaSquarePollVertical className="menu-icon me-4 default-font-size" />
            <span className="menu-title">Reports</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
