import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_FREELANCE_EARNING_DETAILS,
  GET_PAYMENT_REQUEST,
} from "../../../constants/url";
import { axiosDataAcquisitionFreelancer } from "../../../services/api";

export const PaymentReqThunk = createAsyncThunk(
  `wallet/PaymentReqThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        GET_PAYMENT_REQUEST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const FreelanceEarningThunk = createAsyncThunk(
  `wallet/FreelanceEarningThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(
        GET_FREELANCE_EARNING_DETAILS
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);
