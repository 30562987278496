import { Suspense } from "react";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToLogin from "../components/RedirectToLogin";
import PageLoader from "../components/loader/PageLoader";
import Layout from "../layout";
import routesConfig from "./routes.config";
import { useSelector } from "react-redux";

const Public = (route) => {
  // Logic for public routes
  const { Component } = route;

  return (
    <Suspense fallback={<PageLoader />}>
      <Component />
    </Suspense>
  );
};

const Private = (route) => {
  // Logic for Private routes
  const { Component } = route;
  return (
    <Suspense fallback={<PageLoader />}>
      <Component />
    </Suspense>
  );
};

const createNestedRoutes = (routes, RouteType) => {
  return routes
    ?.filter((f) => !!f?.Component)
    .map((route, i) => {
      if (!route.Component) {
        throw new Error("Component must be required....");
      }
      if (route.children) {
        return (
          <Route path={route.path} key={i} element={<RouteType {...route} />}>
            {createNestedRoutes(route.children, RouteType)}
          </Route>
        );
      } else {
        return (
          <Route
            key={i}
            index={route.index}
            path={route.path}
            element={<RouteType {...route} />}
          />
        );
      }
    });
};

const Routes = () => {
  const { isAuth } = useSelector((state) => state.Auth);
  const { private: privateRoutes, public: publicRoutes } = routesConfig;

  return (
    <ReactRouterRoutes>
      {isAuth ? (
        <>
          <Route index element={<RedirectToHome />} />
          <Route path="/" element={<Layout />}>
            {createNestedRoutes(privateRoutes, Private)}
          </Route>
          <Route path="*" element={<RedirectToHome />} />
        </>
      ) : (
        <>
          <Route index element={<RedirectToLogin />} />
          {createNestedRoutes(publicRoutes, Public)}
          <Route path="*" element={<RedirectToLogin />} />
        </>
      )}
    </ReactRouterRoutes>
  );
};

export default Routes;
