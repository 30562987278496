import lazy from "../utils/lazy";

//Public Router
export const AuthMainPage = lazy(() =>
  import(/* webpackChunkName: "AuthMainPage" */ "../modules/Auth")
);
export const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ "../modules/Auth/Login")
);
export const SignupPage = lazy(() =>
  import(/* webpackChunkName: "SignupPage" */ "../modules/Auth/Signup")
);
export const ForgotPasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "ForgotPasswordPage" */ "../modules/Auth/ForgotPassword"
  )
);
export const SetupNewPasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "SetupNewPasswordPage" */ "../modules/Auth/SetupNewPassword"
  )
);

//Private Router
export const DashboardPage = lazy(() =>
  import(/* webpackChunkName: "DashboardPage" */ "../modules/Dashboard")
);
export const WorkSpaceNewDataPage = lazy(() =>
  import(
    /* webpackChunkName: "WorkSpaceNewDataPage" */ "../modules/Workspace/NewData"
  )
);
export const WorkSpaceSaveDataPage = lazy(() =>
  import(
    /* webpackChunkName: "WorkSpaceSaveDataPage" */ "../modules/Workspace/SaveData"
  )
);
export const WorkSpaceSubmitDataPage = lazy(() =>
  import(
    /* webpackChunkName: "WorkSpaceSubmitDataPage" */ "../modules/Workspace/SubmitData"
  )
);
export const WorkSpaceMyQueryDataPage = lazy(() =>
  import(
    /* webpackChunkName: "WorkSpaceMyQueryDataPage" */ "../modules/Workspace/MyQuery"
  )
);
export const MyQueryForReasonPage = lazy(() =>
  import(
    /* webpackChunkName: "WorkSpacMyQueryForReasonPage" */ "../modules/Workspace/pages/QueryReasonPage"
  )
);
export const ProfilePage = lazy(() =>
  import(/* webpackChunkName: "ProfilePage" */ "../modules/Profile")
);
export const GuidLinePage = lazy(() =>
  import(/* webpackChunkName: "GuidLinePage" */ "../modules/Resources/GuidLine")
);
export const TermConditionDataPage = lazy(() =>
  import(
    /* webpackChunkName: "TermConditionDataPage" */ "../modules/Resources/TermCondition"
  )
);

export const QCNewDataPage = lazy(() =>
  import(
    /* webpackChunkName: "QCNewDataPage" */ "../modules/QCManagement/NewData"
  )
);

export const QCSaveDataPage = lazy(() =>
  import(
    /* webpackChunkName: "QCSaveDataPage" */ "../modules/QCManagement/SaveData"
  )
);

export const QCSubmitDataPage = lazy(() =>
  import(
    /* webpackChunkName: "QCSubmitDataPage" */ "../modules/QCManagement/SubmitData"
  )
);

export const ChangePassword = lazy(() =>
  import(
    /* webpackChunkName: "ChangePassword" */ "../modules/Auth/ChangePassword"
  )
);

export const EarningHistory = lazy(() =>
  import(
    /* webpackChunkName: "EarningHistory" */ "../modules/Wallet/EarningHistory"
  )
);

export const PendingWalletReq = lazy(() =>
  import(
    /* webpackChunkName: "PendingWalletReq" */ "../modules/Wallet/PendingWalletReq"
  )
);
