import { createSlice } from "@reduxjs/toolkit";
import { FreelanceEarningThunk, PaymentReqThunk } from "./Thunk";

const initialState = {
  WalletData: [],
  WalletLoading: false,
  walletDataCount: 0,
  error: null,
  EarningData: null,
  EarningDataLoading: false,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(PaymentReqThunk.pending, (state, action) => {
      state.WalletLoading = true;
    });
    builder.addCase(PaymentReqThunk.fulfilled, (state, action) => {
      state.WalletLoading = false;
      state.walletDataCount = action.payload?.data?.user_payment_data?.count;
      state.WalletData = action.payload?.data?.user_payment_data?.rows || [];
    });
    builder.addCase(PaymentReqThunk.rejected, (state, { payload }) => {
      state.error = payload?.message;
      state.WalletLoading = false;
    });

    builder.addCase(FreelanceEarningThunk.pending, (state, action) => {
      state.EarningDataLoading = true;
    });
    builder.addCase(FreelanceEarningThunk.fulfilled, (state, action) => {
      state.EarningDataLoading = false;
      state.EarningData = action.payload?.data;
    });
    builder.addCase(FreelanceEarningThunk.rejected, (state, { payload }) => {
      state.error = payload?.message;
      state.EarningDataLoading = false;
    });
  },
});

export const wallet = walletSlice.actions;

export default walletSlice.reducer;
