import { createSlice } from "@reduxjs/toolkit";
import { getCitySlice, getCountrySlice, getStateSlice } from "./Thunk";

const initialState = {
  countryLoading: false,
  countries: [],
  stateLoading: false,
  states: [],
  cityLoading: false,
  cities: [],
};

const commonSlice = createSlice({
  initialState,
  name: "common",
  extraReducers: (builder) => {
    builder.addCase(getCountrySlice.pending, (state, action) => {
      state.countryLoading = true;
    });
    builder.addCase(getCountrySlice.fulfilled, (state, action) => {
      state.countryLoading = false;
      state.countries = action?.payload?.data?.data;
    });
    builder.addCase(getCountrySlice.rejected, (state, { payload }) => {
      state.countryLoading = false;
    });

    builder.addCase(getStateSlice.pending, (state, action) => {
      state.stateLoading = true;
    });
    builder.addCase(getStateSlice.fulfilled, (state, action) => {
      state.stateLoading = false;
      state.states = action?.payload?.data?.data;
    });
    builder.addCase(getStateSlice.rejected, (state, { payload }) => {
      state.stateLoading = false;
    });

    builder.addCase(getCitySlice.pending, (state, action) => {
      state.cityLoading = true;
    });
    builder.addCase(getCitySlice.fulfilled, (state, action) => {
      state.cityLoading = false;
      state.cities = action?.payload?.data?.data;
    });
    builder.addCase(getCitySlice.rejected, (state, { payload }) => {
      state.cityLoading = false;
    });
  },
});

export default commonSlice.reducer;
