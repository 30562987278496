import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_SLOT_STATUS } from "../../constants/url";
import { axiosDataAcquisitionFreelancer } from "../../services/api";

export const SlotStatusThunk = createAsyncThunk(
  `layout/handleSlotStatus`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(
        GET_SLOT_STATUS
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);
