import { createAsyncThunk } from "@reduxjs/toolkit";
import { FREELANCER_DASHBOARD_DATA } from "../../../constants/url";
import { axiosDataAcquisitionFreelancer } from "../../../services/api";

export const FreelancerDashboardThunk = createAsyncThunk(
  `dashboard/FreelancerDashboardThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(
        FREELANCER_DASHBOARD_DATA
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);
