import { createSlice } from "@reduxjs/toolkit";
import { FreelancerDashboardThunk } from "./Thunk";

const initialState = {
  loading: false,
  dashboardData: null,
};

export const authSlice = createSlice({
  name: "Dashboard",
  initialState,
  extraReducers: (builder) => {
    //Login
    builder.addCase(FreelancerDashboardThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FreelancerDashboardThunk.fulfilled, (state, action) => {
      state.dashboardData = action.payload?.data;
      state.loading = false;
    });
    builder.addCase(FreelancerDashboardThunk.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export default authSlice.reducer;
