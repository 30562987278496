import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosDataAcquisitionFreelancer } from "../../../services/api";
import {
  GET_FREELANCER_PROFILE,
  GET_DOCUMENT_CATEGORY,
  EDIT_PROFILE_DATA,
} from "../../../constants/url";

export const getProfileData = createAsyncThunk(
  `resource/getProfileData`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(
        GET_FREELANCER_PROFILE
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const getDocumentCategoryList = createAsyncThunk(
  `resource/getDocumentCategoryList`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(
        GET_DOCUMENT_CATEGORY
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const editProfileDataThunk = createAsyncThunk(
  `resource/editProfileDataThunk`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        EDIT_PROFILE_DATA,
        payload
      );
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);
