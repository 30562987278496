import { createSlice } from "@reduxjs/toolkit";
import { SlotStatusThunk } from "./Thunk";

const initialState = {
  BodyTopHeaderPath: [],
  slot_status: undefined,
};

export const layoutSlice = createSlice({
  name: "layoutSlice",
  initialState,
  reducers: {
    setBackButtonPath: (state, action) => {
      state.BodyTopHeaderPath = [...state.BodyTopHeaderPath, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SlotStatusThunk.fulfilled, (state, action) => {
      state.slot_status = action?.payload?.data?.is_slot || undefined;
    });
  },
});

export const { setBackButtonPath } = layoutSlice.actions;

export default layoutSlice.reducer;
