import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { SOMETHING_WANTS_WRONG } from "../../../constants/errorMessages";
import {
  freeLancerSaveListThunk,
  freeLancerSubmitListThunk,
  freeProjectDataThunk,
  getQueryListThunk,
  projectDataField,
} from "./Thunk";

const initialState = {
  saveList: {
    saveListData: [],
    saveListLoading: false,
    saveListCount: 0,
    saveListPageNUmber: 1,
    saveListSizePerPage: 10,
    saveListSearch: "",
  },
  submitList: {
    submitListData: [],
    submitListLoading: false,
    submitListCount: 0,
    submitListPageNUmber: 1,
    submitListSizePerPage: 10,
    submitListSearch: "",
  },
  queryList: {
    queryListData: [],
    queryListLoading: false,
    queryListCount: 0,
    queryListPageNUmber: 1,
    queryListSizePerPage: 10,
    queryListSearch: "",
  },
  queryDataFieldLoading: false,
  queryDataField: null,
  projectDataLoading: false,
  projectData: null,
};

export const workspaceSlice = createSlice({
  name: "workspaceSlice",
  initialState,
  reducers: {
    handelSubmitDataPageChange: (state, action) => {
      state.submitList = {
        ...state?.submitList,
        submitListPageNUmber: action?.payload?.page,
        submitListSizePerPage: action?.payload?.sizePerPage,
      };
    },
    handelSubmitDataSearch: (state, action) => {
      state.submitList = {
        ...state?.submitList,
        submitListSearch: action?.payload,
        submitListPageNUmber: 1,
      };
    },
    handelSaveDataPageChange: (state, action) => {
      state.saveList = {
        ...state?.saveList,
        saveListPageNUmber: action?.payload?.page,
        saveListSizePerPage: action?.payload?.sizePerPage,
      };
    },
    handelSaveDataSearch: (state, action) => {
      state.saveList = {
        ...state?.saveList,
        saveListSearch: action?.payload,
        saveListPageNUmber: 1,
      };
    },

    handelQueryListPageChange: (state, action) => {
      state.queryList = {
        ...state?.queryList,
        queryListPageNUmber: action?.payload?.page,
        queryListSizePerPage: action?.payload?.sizePerPage,
      };
    },
    handelQueryListSearch: (state, action) => {
      state.queryList = {
        ...state?.queryList,
        queryListSearch: action?.payload,
        queryListPageNUmber: 1,
      };
    },
  },
  extraReducers: (builder) => {
    //Save List
    builder.addCase(freeLancerSaveListThunk.pending, (state, action) => {
      state.saveList = {
        ...state.saveList,
        saveListLoading: true,
      };
    });
    builder.addCase(freeLancerSaveListThunk.fulfilled, (state, action) => {
      state.saveList = {
        ...state.saveList,
        saveListData: action.payload?.data?.save_data?.rows,
        saveListCount: action.payload?.data?.save_data?.count,
        saveListLoading: false,
      };
    });
    builder.addCase(freeLancerSaveListThunk.rejected, (state, { payload }) => {
      state.saveList = {
        ...state.saveList,
        saveListLoading: false,
      };
      toast.error(payload?.message);
    });
    //Submit List
    builder.addCase(freeLancerSubmitListThunk.pending, (state, action) => {
      state.submitList = {
        ...state.submitList,
        submitListLoading: true,
      };
    });
    builder.addCase(freeLancerSubmitListThunk.fulfilled, (state, action) => {
      state.submitList = {
        ...state.submitList,
        submitListData: action.payload?.data?.save_data?.rows,
        submitListCount: action.payload?.data?.save_data?.count,
        submitListLoading: false,
      };
    });
    builder.addCase(
      freeLancerSubmitListThunk.rejected,
      (state, { payload }) => {
        state.submitList = {
          ...state.submitList,
          submitListLoading: false,
        };
        toast.error(payload?.message);
      }
    );
    //Project Data
    builder.addCase(freeProjectDataThunk.pending, (state, action) => {
      state.projectDataLoading = true;
    });
    builder.addCase(freeProjectDataThunk.fulfilled, (state, action) => {
      state.projectData = action.payload?.data?.data;
      state.projectDataLoading = false;
    });
    builder.addCase(freeProjectDataThunk.rejected, (state, { payload }) => {
      state.projectDataLoading = false;
      toast.error(
        payload?.response?.data?.message ||
          payload?.message ||
          SOMETHING_WANTS_WRONG
      );
    });
    //Query Data getQueryListThunk
    builder.addCase(getQueryListThunk.pending, (state, action) => {
      state.queryList = {
        ...state.queryList,
        queryListLoading: true,
      };
    });
    builder.addCase(getQueryListThunk.fulfilled, (state, action) => {
      state.queryList = {
        ...state.queryList,
        queryListData: action.payload?.data?.query_data?.rows,
        queryListCount: action.payload?.data?.query_data?.count,
        queryListLoading: false,
      };
    });
    builder.addCase(getQueryListThunk.rejected, (state, { payload }) => {
      state.queryList = {
        ...state.queryList,
        queryListLoading: false,
      };
      toast.error(payload?.message);
    });
    //query profile data
    builder.addCase(projectDataField.pending, (state, action) => {
      state.queryDataFieldLoading = true;
    });
    builder.addCase(projectDataField.fulfilled, (state, action) => {
      state.queryDataFieldLoading = false;
      state.queryDataField = action.payload?.data;
    });
    builder.addCase(projectDataField.rejected, (state, { payload }) => {
      state.queryDataFieldLoading = false;
      toast.error(payload?.message);
    });
  },
});

export const {
  handelSubmitDataPageChange,
  handelSaveDataPageChange,
  handelSaveDataSearch,
  handelSubmitDataSearch,
  handelQueryListPageChange,
  handelQueryListSearch,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
