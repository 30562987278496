import axios from "axios";
import Cookies from "./cookies";
import store from "../store";
import { logout } from "../modules/Auth/slice/AuthSlice";
import { SIGN_UP } from "../constants/url";

const BASE_URL = process.env.REACT_APP_FREELANCER_API;

export const DATA_ACQUISITION_FREELANCER = `${BASE_URL}/api/v1`;

class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);

    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
  }

  _requestMiddleware = (req) => {
    // Send Bearer token on every request
    const token = Cookies.get("token");
    if (!!token)
      req.headers.authorization = token.startsWith("Token ")
        ? token
        : "Token " + token;
    return req;
  };

  _responseMiddleware = (response) => {
    //  Do something on every success full response.
    if (response?.data?.data?.token && response?.config?.url !== SIGN_UP) {
      Cookies.set("token", response?.data?.data?.token);
    }
    return response;
  };

  _responseErr = (error) => {
    //if error comes than handle here
    if (error?.response?.status === 401) {
      Cookies?.clear();
      store.dispatch(logout());
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
}

const axiosDataAcquisitionFreelancer = new Axios(DATA_ACQUISITION_FREELANCER)
  .axios;

export { axiosDataAcquisitionFreelancer };
