import { createAsyncThunk } from "@reduxjs/toolkit";
import { LIST_CITY, LIST_COUNTRY, LIST_STATE } from "../../constants/url";
import { axiosDataAcquisitionFreelancer } from "../../services/api";

export const getCountrySlice = createAsyncThunk(
  `common/getCountrySlice`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(LIST_COUNTRY);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const getStateSlice = createAsyncThunk(
  `common/getStateSlice`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(
        `${LIST_STATE}/${+payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const getCitySlice = createAsyncThunk(
  `common/getCitySlice`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(
        `${LIST_CITY}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);
