import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { SOMETHING_WANTS_WRONG } from "../../../constants/errorMessages";
import {
  QCProjectDataThunk,
  QCSaveDataThunk,
  QCSubmitDataThunk,
} from "./Thunk";

const initialState = {
  saveList: {
    saveListData: [],
    saveListLoading: false,
    saveListCount: 0,
    saveListPageNUmber: 1,
    saveListSizePerPage: 10,
    saveListSearch: "",
  },
  submitList: {
    submitListData: [],
    submitListLoading: false,
    submitListCount: 0,
    submitListPageNUmber: 1,
    submitListSizePerPage: 10,
    submitListSearch: "",
  },

  projectDataLoading: false,
  projectData: null,
};

export const QCSlices = createSlice({
  name: "QCSlices",
  initialState,
  reducers: {
    handelQCSubmitDataPageChange: (state, action) => {
      state.submitList = {
        ...state?.submitList,
        submitListPageNUmber: action?.payload?.page,
        submitListSizePerPage: action?.payload?.sizePerPage,
      };
    },
    handelQCSubmitDataSearch: (state, action) => {
      state.submitList = {
        ...state?.submitList,
        submitListSearch: action?.payload,
        submitListPageNUmber: 1,
      };
    },
    handelQCSaveDataPageChange: (state, action) => {
      state.saveList = {
        ...state?.saveList,
        saveListPageNUmber: action?.payload?.page,
        saveListSizePerPage: action?.payload?.sizePerPage,
      };
    },
    handelQCSaveDataSearch: (state, action) => {
      state.saveList = {
        ...state?.saveList,
        saveListSearch: action?.payload,
        saveListPageNUmber: 1,
      };
    },
  },
  extraReducers: (builder) => {
    //Project Data
    builder.addCase(QCProjectDataThunk.pending, (state, action) => {
      state.projectDataLoading = true;
    });
    builder.addCase(QCProjectDataThunk.fulfilled, (state, action) => {
      state.projectData = action.payload?.data?.data;
      state.projectDataLoading = false;
    });
    builder.addCase(QCProjectDataThunk.rejected, (state, { payload }) => {
      state.projectDataLoading = false;
      state.projectData = null;
      toast.error(
        payload?.response?.data?.message ||
          payload?.message ||
          SOMETHING_WANTS_WRONG
      );
    });
    //Save List
    builder.addCase(QCSaveDataThunk.pending, (state, action) => {
      state.saveList = {
        ...state.saveList,
        saveListLoading: true,
      };
    });
    builder.addCase(QCSaveDataThunk.fulfilled, (state, action) => {
      state.saveList = {
        ...state.saveList,
        saveListData: action.payload?.data?.save_data?.rows,
        saveListCount: action.payload?.data?.save_data?.count,
        saveListLoading: false,
      };
    });
    builder.addCase(QCSaveDataThunk.rejected, (state, { payload }) => {
      state.saveList = {
        ...state.saveList,
        saveListLoading: false,
      };
      toast.error(payload?.message);
    });
    //Submit List
    builder.addCase(QCSubmitDataThunk.pending, (state, action) => {
      state.submitList = {
        ...state.submitList,
        submitListLoading: true,
      };
    });
    builder.addCase(QCSubmitDataThunk.fulfilled, (state, action) => {
      state.submitList = {
        ...state.submitList,
        submitListData: action.payload?.data?.save_data?.rows,
        submitListCount: action.payload?.data?.save_data?.count,
        submitListLoading: false,
      };
    });
    builder.addCase(QCSubmitDataThunk.rejected, (state, { payload }) => {
      state.submitList = {
        ...state.submitList,
        submitListLoading: false,
      };
      toast.error(payload?.message);
    });
  },
});

export const {
  handelQCSaveDataPageChange,
  handelQCSaveDataSearch,
  handelQCSubmitDataPageChange,
  handelQCSubmitDataSearch,
} = QCSlices.actions;

export default QCSlices.reducer;
