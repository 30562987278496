import { Navigate, Outlet } from "react-router-dom";
import RedirectToLogin from "../components/RedirectToLogin";
import {
  AuthMainPage,
  ChangePassword,
  DashboardPage,
  EarningHistory,
  ForgotPasswordPage,
  GuidLinePage,
  LoginPage,
  MyQueryForReasonPage,
  PendingWalletReq,
  ProfilePage,
  QCNewDataPage,
  QCSaveDataPage,
  QCSubmitDataPage,
  SetupNewPasswordPage,
  SignupPage,
  TermConditionDataPage,
  WorkSpaceMyQueryDataPage,
  WorkSpaceNewDataPage,
  WorkSpaceSaveDataPage,
  WorkSpaceSubmitDataPage,
} from "./routeImports";
import routesConstants from "./routesConstants";
import Cookies from "../services/cookies";
const user = Cookies.get("user");

const routesConfig = {
  private: [
    {
      path: routesConstants.HOME_PAGE,
      Component: DashboardPage,
    },
    {
      path: routesConstants.QUERY_REASON,
      Component: MyQueryForReasonPage,
    },
    !!user?.permission?.is_qc
      ? {
          path: routesConstants.QC_MANAGEMENT,
          Component: () => <Outlet />,
          children: [
            {
              index: true,
              Component: () => (
                <Navigate
                  to={
                    "/" +
                    routesConstants.QC_MANAGEMENT +
                    "/" +
                    routesConstants.QC_NEW_DATA
                  }
                />
              ),
            },
            {
              path: routesConstants.QC_NEW_DATA,
              Component: QCNewDataPage,
            },
            {
              path: routesConstants.QC_NEW_DATA_WITH_ID,
              Component: QCNewDataPage,
            },
            {
              path: routesConstants.QC_SAVE_DATA,
              Component: QCSaveDataPage,
            },
            {
              path: routesConstants.QC_SUBMIT_DATA,
              Component: QCSubmitDataPage,
            },
          ],
        }
      : {},

    !!user?.permission?.is_freelancer
      ? {
          path: routesConstants.WORK_SPACE,
          Component: () => <Outlet />,
          children: [
            {
              index: true,
              Component: () => (
                <Navigate
                  to={
                    "/" +
                    routesConstants.WORK_SPACE +
                    "/" +
                    routesConstants.NEW_DATA
                  }
                />
              ),
            },
            {
              path: routesConstants.NEW_DATA,
              Component: WorkSpaceNewDataPage,
            },
            {
              path: routesConstants.NEW_DATA_WITH_ID,
              Component: WorkSpaceNewDataPage,
            },
            {
              path: routesConstants.SAVE_DATA,
              Component: WorkSpaceSaveDataPage,
            },
            {
              path: routesConstants.SUBMIT_DATA,
              Component: WorkSpaceSubmitDataPage,
            },
            {
              path: routesConstants.MY_QUERY,
              Component: WorkSpaceMyQueryDataPage,
            },
            {
              path: routesConstants.MY_QUERY_PREVIEW,
              Component: MyQueryForReasonPage,
            },
          ],
        }
      : {},
    {
      path: routesConstants.PROFILE,
      Component: ProfilePage,
    },
    {
      path: routesConstants.RESOURCES,
      Component: () => <Outlet />,
      children: [
        {
          index: true,
          Component: () => (
            <Navigate
              to={
                "/" +
                routesConstants.RESOURCES +
                "/" +
                routesConstants.GUIDE_LINE
              }
            />
          ),
        },
        {
          path: routesConstants.GUIDE_LINE,
          Component: GuidLinePage,
        },
        {
          path: routesConstants.TERMS_CONDITION,
          Component: TermConditionDataPage,
        },
      ],
    },
    {
      path: routesConstants.WALLET,
      Component: () => <Outlet />,
      children: [
        {
          index: true,
          Component: () => (
            <Navigate
              to={
                "/" +
                routesConstants.WALLET +
                "/" +
                routesConstants.EARNING_HISTORY
              }
            />
          ),
        },
        {
          path: routesConstants.EARNING_HISTORY,
          Component: EarningHistory,
        },
        {
          path: routesConstants.PENDING_WALLET,
          Component: PendingWalletReq,
        },
      ],
    },
    {
      path: routesConstants.CHANGE_PASSWORD,
      Component: ChangePassword,
    },
  ],

  public: [
    {
      path: routesConstants.AUTH,
      Component: AuthMainPage,
      children: [
        {
          index: true,
          Component: () => <RedirectToLogin />,
        },
        {
          path: routesConstants.LOGIN,
          Component: LoginPage,
        },
        {
          path: routesConstants.SIGNUP,
          Component: SignupPage,
        },
        {
          path: routesConstants.FORGOT_PASSWORD,
          Component: ForgotPasswordPage,
        },
        {
          path: routesConstants.SETUP_NEW_PASSWORD,
          Component: SetupNewPasswordPage,
        },
        {
          path: "*",
          Component: () => <RedirectToLogin />,
        },
      ],
    },
  ],
};

export default routesConfig;
