import { ErrorBoundary } from "react-error-boundary";

const Fallback = () => {
  return "Error occurred";
};

const ErrorBoundaryLayout = ({ children }) => {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
};
export default ErrorBoundaryLayout;
