import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosDataAcquisitionFreelancer } from "../../../services/api";
import {
  FORGET_PASSWORD,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  SIGN_UP,
} from "../../../constants/url";

export const LoginThunk = createAsyncThunk(
  `authentication/LoginThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        LOGIN,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const LogoutThunk = createAsyncThunk(
  `authentication/LogoutThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(LOGOUT);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const SignUpThunk = createAsyncThunk(
  `authentication/SignUpThunk`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        SIGN_UP,
        payload
      );
      cb(response.data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const ForgetPasswordThunk = createAsyncThunk(
  `authentication/ForgetPasswordThunk`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.put(
        FORGET_PASSWORD,
        payload
      );
      cb(response?.data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const ResetPasswordThunk = createAsyncThunk(
  `authentication/ResetPasswordThunk`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.put(
        RESET_PASSWORD,
        payload
      );
      cb(response?.data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);
