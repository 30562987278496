import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  QC_NEW_PROJECT_DATA,
  QC_SAVE_DATA_LIST,
  QC_SUBMIT_DATA_LIST,
} from "../../../constants/url";
import { axiosDataAcquisitionFreelancer } from "../../../services/api";

export const QCProjectDataThunk = createAsyncThunk(
  `QC/QCProjectDataThunk`,
  async (payload, thunkAPI) => {
    try {
      let url = !!payload
        ? `${QC_NEW_PROJECT_DATA}/${payload}`
        : `${QC_NEW_PROJECT_DATA}/${0}`;
      const response = await axiosDataAcquisitionFreelancer.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const QCSaveDataThunk = createAsyncThunk(
  `QC/QCSaveDataThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        QC_SAVE_DATA_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const QCSubmitDataThunk = createAsyncThunk(
  `QC/QCSubmitDataThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        QC_SUBMIT_DATA_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);
