import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosDataAcquisitionFreelancer } from "../../../services/api";
import { GUID_LINE, TERM_CONDITION } from "../../../constants/url";

export const getGuidLineThunk = createAsyncThunk(
  `resource/getGuidLineThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(GUID_LINE);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const termConditionThunk = createAsyncThunk(
  `resource/termConditionThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(TERM_CONDITION);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);
