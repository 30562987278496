import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getGuidLineThunk, termConditionThunk } from "./Thunk";

const initialState = {
  guidLineDataLoading: false,
  guidLine: null,
  termConditionLoading: false,
  termCondition: null,
};

export const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGuidLineThunk.pending, (state, action) => {
      state.guidLineDataLoading = true;
    });
    builder.addCase(getGuidLineThunk.fulfilled, (state, action) => {
      state.guidLineDataLoading = false;
      state.guidLine = action?.payload?.data?.data;
    });
    builder.addCase(getGuidLineThunk.rejected, (state, { payload }) => {
      state.guidLineDataLoading = false;
      toast.error(payload?.message);
    });

    //Term Condition
    builder.addCase(termConditionThunk.pending, (state, action) => {
      state.termConditionLoading = true;
    });
    builder.addCase(termConditionThunk.fulfilled, (state, action) => {
      state.termConditionLoading = false;
      state.termCondition = action?.payload?.data?.data;
    });
    builder.addCase(termConditionThunk.rejected, (state, { payload }) => {
      state.termConditionLoading = false;
      toast.error(payload?.message);
    });
  },
});

export const { logout, setLoginError, setAuth } = resourceSlice.actions;

export default resourceSlice.reducer;
